@import "../../../assets/style/app";

.h2Wrapper {
  display: flex;
  align-items: center;

  .dot {
    background: $color_grey;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 8px;

    @include tablet {
      width: 13px;
      height: 13px;
      margin-right: 12px;
    }

    @include desktop {
      width: 16px;
      height: 16px;
      margin-right: 16px;
    }

  }


  .h2 {
    white-space: nowrap;
    font-size: 20px;

    @include tablet {
      font-size: 26px;
    }

    @include desktop {
      font-size: 32px;
    }

    span {
      display: inline-block;
      font-weight: 700;
      color: #b2b2b2;
    }

    .empty {
      width: 6px;

      @include tablet {
        width: 9px;
      }

      @include desktop {
        width: 10px;
      }
    }
  }
}



//========= BREAK POINTS ===========//
@mixin tablet {
  @media all and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media all and (min-width: 1240px) {
    @content;
  }
}

@mixin onlyTabletAndLess($displayType: block) {
  display: $displayType;
  @content;

  @include desktop {
    display: none;
  }
}

@mixin onlyMobile($displayType: block) {
  display: $displayType;
  @content;

  @include tablet {
    display: none;
  }
}

@mixin onlyTablet($displayType: block) {
  display: none;

  @include tablet {
    display: $displayType;
    @content;
  }

  @include desktop {
    display: none;
  }
}

@mixin onlyDesktop($displayType: block) {
  display: none;

  @include desktop {
    display: $displayType;
    @content;
  }
}

//========= CONTAINERS ===========//
@mixin container {
  padding-left: 16px;
  padding-right: 16px;

  @include tablet {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin blockPaddings {
  padding-top: 40px;
  padding-bottom: 40px;

  @include tablet {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include desktop {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@mixin inner {
  @include desktop {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin mt {
  margin-top: 16px;

  @include tablet {
    margin-top: 24px;
  }

  @include desktop {
    margin-top: 32px;
  }
}

//========= BLOCKS GRADIENT ===========//
@mixin firstBlockGradient {
  background: linear-gradient($color_background_1, $color_background_1 98%, $color_background_2);
}

@mixin evenBlockGradient {
  background: linear-gradient($color_background_2, $color_background_2 2%, $color_background_2 98%, $color_background_1);
}

@mixin oddBlockGradient {
  background: linear-gradient($color_background_1, $color_background_1 2%, $color_background_1 98%, $color_background_2);
}

//========= TEXT ===========//
@mixin Anton {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin ArchivoBlack {
  font-family: "Archivo Black", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin TitanOne {
  font-family: "Titan One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin h1 {
  font-size: 32px;

  @include tablet {
    font-size: 40px;
  }

  @include desktop {
    font-size: 48px;
  }
}

@mixin h2 {
  font-size: 24px;

  @include tablet {
    font-size: 36px;
  }

  @include desktop {
    font-size: 40px;
  }
}

@mixin h3 {
  font-size: 20px;

  @include tablet {
    font-size: 32px;
  }

  @include desktop {
    font-size: 36px;
  }
}

@mixin h4 {
  font-size: 18px;

  @include tablet {
    font-size: 28px;
  }

  @include desktop {
    font-size: 32px;
  }
}

//========= OTHER ===========//
@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// 10px - 2.5
// 100px - 25px
@import "../../../assets/style/app";

.animatedTitle {
  @include mt;
  position: relative;
  height: 32px;

  @include tablet {
    height: 36px;
  }

  @include desktop {
    height: 48px;
  }

  .title {
    @include h1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @include tablet {
      left: 0;
      transform: translateX(0);
    }


    span {
      display: inline-block;
      opacity: 0;
      @include ArchivoBlack;
      color: #8b94ff;
    }

    .empty {
      width: calc(32px / 4);

      @include tablet {
        font-size: calc(36px / 4);
      }

      @include desktop {
        font-size: calc(48px / 4);
      }
    }

  }
}
@import "../../assets/style/app";

.burgerMenu {
  @include centerColumn;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #151515;
  transition: 0.3s;
  transform: translateX(101%);


  &_open {
    transform: translateX(0%);
  }

  .links {
    @include centerColumn;

    .link {
      text-decoration: none;
      font-size: 36px;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }
  }

}
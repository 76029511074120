@import "../../../assets/style/app";

.container {
  @include container;
  @include blockPaddings;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .inner {
    @include inner;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
$color_primary_light: #FFF;
$color_primary_main: #FFF;
$color_primary_dark: #FFF;

$color_secondary_light: #FFF;
$color_secondary_main: #FFF;
$color_secondary_dark: #FFF;

$color_background_1: #161642FF;
$color_background_2: #254242;//darkslategray;

$color_grey: #b2b2b2;
@import "../../../assets/style/app";

.contactMeButton {
  @include centerRow;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  border: 2px solid lime;
  border-radius: 8px;
  width: 0.7 * 200px;
  height: 0.7 * 60px;
  margin-top: 36px;
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    margin-top: 48px;
    margin-left: 0;
    margin-right: 0;
    width: 0.85 * 200px;
    height: 0.85 * 60px;
  }

  @include desktop {
    margin-top: 60px;
    width: 200px;
    height: 60px;
  }

  .label {
    position: relative;
    z-index: 2;
    transition: 0.6s;
    color: lime;
    font-size: 18px;

    @include tablet {
      font-size: 20px;
    }

    @include desktop {
      font-size: 24px;
    }
  }

  .items {
    //border: 1px solid #FFF;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(15deg) scaleX(1.1) scaleY(1.9);

    .item {
      position: absolute;
      z-index: 1;
      background-color: lime;
      border-radius: 2px;
      transition: 0.8s;

      &:nth-child(odd) {
        top: 0;
        transform: translateY(-100%) scaleX(1.05);
      }

      &:nth-child(even) {
        bottom: 0;
        transform: translateY(100%) scaleX(1.05);
      }
    }
  }

  &:hover {

    @include desktop {
      .item {
        &:nth-child(odd) {
          top: 100%;
          //transform: translateY(-50%) scaleX(1.1);
        }

        &:nth-child(even) {
          bottom: 100%;
          //transform: translateY(50%) scaleX(1.1);
        }

        //transform: translateY(0)!important;
      }

      .label {
        color: $color_background_1;
      }
    }

  }
}
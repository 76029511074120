.app {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;

  &_noScroll {
    height: 100vh;
    overflow: hidden;
  }

}
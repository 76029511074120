@import "../../assets/style/app";

.mySkills {
  @include evenBlockGradient;

  .innerWrapper {

    @include desktop {
      display: flex;
      align-items: flex-start;
    }

    .canvasWrapper {
      //border: 1px solid #FFF;
      margin-top: 16px;
      height: calc(0.8 * (100vw - 32px));

      @include tablet {
        margin-top: 24px;
      }

      @include desktop {
        margin-top: 0;
        margin-left: 40px;
        flex-grow: 1;
        height: calc(100vh - 160px);
      }
    }
  }

}
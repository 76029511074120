@import "../../../assets/style/app";

.waveLink {
  &:not(:first-child) {
    margin-left: 20px;

    @include desktop {
      margin-left: 24px;
    }
  }

  p {
    display: inline-block;
    font-size: 20px;

    @include desktop {
      font-size: 24px;
    }

    span {
      display: inline-block;
    }
  }

  .empty {
    width: 4px;

    @include desktop {
      font-size: 24px;
    }
  }
}
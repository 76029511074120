@import "../../../assets/style/app";

.building {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  //border: 5px solid $color_background_1;
  overflow: hidden;
  box-sizing: border-box;

  .maskTop {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0px;
    width: 100%;
    height: 5%;
    background: linear-gradient(rgb(22, 22, 66), rgb(22, 22, 66) 5%, rgba(22, 22, 66, 0));
  }

  .maskBottom {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 5%;
    background: linear-gradient(rgba(22, 22, 66, 0), rgba(22, 22, 66, 1));
  }

  .maskRight {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    width: 5%;
    height: 100%;
    background: linear-gradient(90deg, rgba(22, 22, 66, 0), rgba(22, 22, 66, 1));
  }

  .content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 1;
      border-radius: 4px;
      width: 90%;
      height: 7%;
      left: 5%;
      padding: 0 12px;
      box-sizing: border-box;

      @include tablet {
        padding: 0 16px;
      }

      .mask {
        @include centerRow;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #394462;
        border-radius: 4px;
      }

      .copyright {
        color: $color_background_1;
        font-size: 12px;

        @include tablet {
          font-size: 14px;
        }

        @include desktop {
          font-size: 18px;
        }
      }

      .links {
        display: flex;
        align-items: center;

        .link {
          color: $color_background_1;
          font-weight: bold;
          font-size: 12px;

          @include tablet {
            font-size: 14px;
          }

          @include desktop {
            font-size: 18px;
          }

          &:not(:first-child) {
            margin-left: 8px;

            @include tablet {
              margin-left: 12px;
            }

            @include desktop {
              margin-left: 16px;
            }
          }
        }
      }
    }

    .sidebar {
      position: absolute;
      z-index: 1;
      background-color: transparent;
      border-radius: 4px;
      width: 25%;
      height: 62%;
      left: 5%;
      padding: 8px 4px;
      box-sizing: border-box;
      //box-shadow: 3px 3px 5px #0b0b21;

      .mask {
        @include centerRow;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #394462;
        border-radius: 4px;
      }

      .topic {
        position: relative;
        z-index: 1;
        @include centerRow;
        border-radius: 4px;
        padding: 4px 0;
        background: rgb(57, 164, 117);
        box-shadow: 2px 2px 2px rgb(9, 26, 19);
        color: $color_background_1;
        font-size: 12px;

        @include tablet {
          font-size: 14px;
        }

        @include desktop {
          font-size: 18px;
        }

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }

    .main {
      position: absolute;
      z-index: 1;
      //background-color: #394462;
      border-radius: 4px;
      width: 63%;
      height: 62%;
      left: 32%;
      padding: 8px;
      box-sizing: border-box;

      @include tablet {
        padding: 12px;
      }

      @include desktop {
        padding: 16px;
      }

      .mask {
        @include centerRow;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #394462;
        border-radius: 4px;
      }

      .block {
        display: flex;

        @include desktop {
          justify-content: space-between;
        }

        &:nth-child(1) {
          .texts {
            margin-left: 8px;

            @include tablet {
              margin-left: 16px;
            }

            @include desktop {
              margin-left: 0;
            }
          }
        }

        &:nth-child(2) {
          .texts {
            margin-right: 8px;

            @include tablet {
              margin-right: 16px;
            }

            @include desktop {
              margin-right: 0;
            }
          }
        }

        &:nth-child(2) {
          flex-direction: row-reverse;
          margin-top: 8px;

          @include tablet {
            margin-top: 12px;
          }

          @include desktop {
            margin-top: 24px;
          }

          .texts {
            align-items: flex-end;

            .text {
              text-align: end;
            }
          }

        }

        .picture {
          flex-shrink: 0;
          width: 80px;
          height: 80px;

          @include desktop {
            width: calc(0.5 * (100% - 16px) - 4px);
            height: calc(0.5 * (100% - 16px) - 4px);
          }

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: $color_background_1;
            }
          }
        }

        .texts {
          display: flex;
          flex-direction: column;
          width: auto;

          @include desktop {
            width: calc(0.5 * (100% - 16px) - 4px);
          }

          .title {
            font-weight: bold;
            color: $color_background_1;
            font-size: 18px;

            @include tablet {
              font-size: 24px;
            }

            @include desktop {
              font-size: 32px;
            }
          }

          .text {
            color: $color_background_1;
            font-size: 12px;

            @include tablet {
              font-size: 14px;
            }

            @include desktop {
              font-size: 18px;
            }
          }
        }

      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 1;
      background-color: transparent;
      border-radius: 4px;
      width: 90%;
      height: 7%;
      left: 5%;
      padding: 0 12px;
      box-sizing: border-box;

      @include tablet {
        padding: 0 16px;
      }

      .logo {
        color: $color_background_1;
        font-weight: 900;
        font-size: 18px;

        @include tablet {
          font-size: 24px;
        }

        @include desktop {
          font-size: 32px;
        }
      }

      .links {
        display: flex;
        align-items: center;

        .link {
          color: $color_background_1;
          font-weight: bold;
          font-size: 12px;

          @include tablet {
            font-size: 14px;
          }

          @include desktop {
            font-size: 18px;
          }

          &:not(:first-child) {
            margin-left: 8px;

            @include tablet {
              margin-left: 12px;
            }

            @include desktop {
              margin-left: 16px;
            }
          }
        }
      }

      .mask {
        @include centerRow;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #394462;
        border-radius: 4px;
      }
    }
  }



  .label {
    font-weight: bold;
    //color: #4d4d7a;
    color: #484f62;
    font-size: 14px;
    text-shadow: 2px 2px 2px $color_background_1;

    @include tablet {
      font-size: 18px;
    }

    @include desktop {
      font-size: 24px;
    }
  }

}
@import "../../assets/style/app";

.contact {
  background-color: $color_background_2;

  .content {
    position: relative;
    flex-grow: 1;

    @include tablet {
      display: flex;
      align-items: center;
    }

    .contentInner {

      @include tablet {
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
      }

      @include desktop {
        grid-column-gap: 60px;
      }

      .left {

        .titleWrapper {

          @include tablet {
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .title {
          @include h1;
          @include ArchivoBlack;
          margin-top: 16px;

          @include tablet {
            margin-top: 0;
          }
        }

        .text {
          @include h3;
        }
      }

      .right {
        margin-top: 32px;

        @include tablet {
          margin-top: 0;
        }

        .submitButton {
          @include button;
          @include centerRow;
          overflow: hidden;
          position: relative;
          border: 2px solid #8B94FFFF;
          border-radius: 8px;
          width: 0.7 * 200px;
          height: 0.7 * 60px;
          margin-left: 26px;
          margin-top: 8px;

          @include tablet {
            width: 0.85 * 200px;
            height: 0.85 * 60px;
            margin-left: 36px;
            margin-top: 16px;
          }

          @include desktop {
            width: 200px;
            height: 60px;
          }

          .label {
            position: relative;
            z-index: 2;
            transition: 0.6s;
            color: #8B94FFFF;
            font-size: 18px;

            @include tablet {
              font-size: 20px;
            }

            @include desktop {
              font-size: 24px;
            }
          }

          svg {
            position: relative;
            z-index: 2;
            width: 18px;
            height: 18px;
            margin-left: 9px;

            @include tablet {
              width: 20px;
              height: 20px;
              margin-left: 10px;
            }

            @include desktop {
              width: 24px;
              height: 24px;
              margin-left: 12px;
            }

            path {
              transition: 0.6s;
              fill: #FFF;//#8B94FFFF;
            }

          }

          .boxWrapper {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 0;
            width: 0;
            height: 0;
            transform: rotate(-45deg);
            transition: 0.6s;
          }

          .box {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 400px;
            height: 400px;
            background: #8B94FFFF;//#12d3d3;
          }

          &:hover {
            @include desktop {
              .boxWrapper {
                left: calc(100% + 60px);
              }

              .label {
                color: #064141;
              }

              svg path {
                fill: #000;
              }
            }
          }

          &:disabled {
            border-color: grey;

            .boxWrapper {
              display: none;
            }

            .label {
              color: grey;
            }

            svg path {
              fill: grey;
            }
          }

        }

      }
    }


  }


}
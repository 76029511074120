@import "../../assets/style/app";

.about {
  @include firstBlockGradient;
  display: flex;
  flex-direction: column;
  padding-top: 40px + 16px !important;

  @include tablet {
    padding-top: 60px + 24px !important;
  }

  @include desktop {
    padding-top: 80px + 40px !important;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }

    .left {
      position: relative;

      @include tablet {
        width: calc(50% - 15px);
      }

      @include desktop {
        width: calc(50% - 30px);
      }

      .buildingWrapper {
        display: none;

        @include tablet {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 80%;
          transform: translateY(-50%);
        }
      }
    }

    .right {
      margin-top: 16px;

      @include tablet {
        width: calc(50% - 15px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @include desktop {
        width: calc(50% - 30px);
      }

      .pretitle {
        @include h2;
        @include ArchivoBlack;
        text-align: center;

        @include tablet {
          text-align: left;
        }
      }

      .title {
        @include h1;
        @include ArchivoBlack;
        color: lime;
        text-align: center;

        @include tablet {
          text-align: left;
        }
      }

      .text {
        @include h2;
      }

    }

    .buildingWrapperMobile {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      @include tablet {
        display: none;
      }
    }

  }
}

.texts {
  @include mt;

  .title {
    @include h2;

    span {
      color: lime;
      @include ArchivoBlack;
    }
  }
}
@import "../../assets/style/app";

.header {
  @include container;
  background-color: #394462;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
  transition: 0.3s;
  transform: translateY(0%);

  @include tablet {
    height: 60px;
  }

  @include desktop {
    height: 80px;
  }

  &_scroll {
    //background-color: rgba(21, 30, 51, 0.5);

    @include tablet {
      height: 0.7 * 60px;
    }

    @include desktop {
      height: 0.7 * 80px;
    }
  }

  &_hide {
    transform: translateY(-101%);
  }

  .inner {
    @include inner;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      text-decoration: none;
      font-size: 20px;

      @include tablet {
        font-size: 24px;
      }

      @include desktop {
        font-size: 36px;
      }
    }

    .burgerBtn {
      @include onlyMobile();
      @include button;
      @include centerRow;
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: #FFF;
        }
      }
    }

    .links {
      display: none;

      @include tablet {
        display: flex;
        height: 100%;
        align-items: center;
      }

    }

  }
}
@import "../../../assets/style/app";

.fieldCustom {
  padding-bottom: 14px;
  padding-left: 26px;

  @include tablet {
    padding-bottom: 18px;
    padding-left: 36px;
  }

  .label {
    font-size: 14px;
    text-transform: uppercase;
    color: $color_grey;
    position: relative;
    left: 4px;

    @include tablet {
      font-size: 16px;
      right: 6px;
    }
  }

  .inputWrapper {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #5f7575;
    padding: 10px 14px;
    background-color: #354242;
    transition: 0.3s;
    border-radius: 4px;
    margin-top: 4px;

    @include tablet {
      padding: 12px 16px;
      border-radius: 6px;
      margin-top: 6px;
    }

    &_focus {
      box-shadow: 0 0 4px lime;
      border-color: lime;

      @include tablet {
        box-shadow: 0 0 6px lime;
      }
    }

    &_error {
      box-shadow: 0 0 4px red;
      border: 1px solid  red;
      background: rgba(255,0,0,0.1);

      @include tablet {
        box-shadow: 0 0 6px red;
      }
    }

    input {
      width: 100%;
      background: none!important;
      border: none!important;
      outline: none!important;
      font-size: 16px;

      &:-internal-autofill-selected {
        appearance: auto;
        background-image: none !important;
        background-color: transparent!important;
        //color: a;
      }

      @include tablet {
        font-size: 18px;
      }
    }

    .errorWrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(120%);
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;

        @include tablet {
          width: 14px;
          height: 14px;
        }

        path {
          fill: red;
        }
      }

      .error {
        color: red;
        font-size: 14px;
        margin-left: 2px;

        @include tablet {
          font-size: 16px;
          margin-left: 3px;
        }
      }
    }

    .iconWrapper {
      position: absolute;
      top: 50%;
      left: -8px;
      transform: translate(-100%, -50%);
      width: 18px;
      height: 18px;

      @include tablet {
        left: -12px;
        width: 24px;
        height: 24px;
      }

      svg {
        opacity: 0.5;
        width: 18px;
        height: 18px;

        @include tablet {
          width: 24px;
          height: 24px;
        }

        path {
          fill: $color_grey;
        }
      }
    }


  }



}